.DashboardProfileSetting{
    display: flex;
    flex-direction: column;
    gap:16px;
    color:var(--headerColor2)
}

.profile-logo{
    background-color: white;
    display: flex;
    flex-direction: column;
    padding:16px;
}

.logo-container{
    background-color: #F5F5F8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width:60px;
    height: 60px;
    font-size: 30px;
   

}
.banking-details{
margin: 0 auto;
width:auto;
}
.verification-status{
    background-color: #CF5A5A;
    padding:8px;
    color:white;
    display: inline-block;
    border-radius: 8px;

}

.profile-details, .account-limits{
    background-color: white;
    display: flex;
    flex-direction: column;
    padding:16px;
}
.profile-details>div, .account-limits>div{
    display: flex;
    gap: 12px;
    padding: 16px 0;
    border-bottom: 1px solid #F5F5F8;
}

.profile-detail-label{
    width: 100px;
}
.account-limits-label{
    width:200px;
}

