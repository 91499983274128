.investment-container{
color:var(--headerColor2);
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
}
.investment-wrapper-header{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.InvestmentCard{ 
    padding:30px;
    background-color: white;
    border-radius: 20px;
}

.investment-top{
    display: flex;
    justify-content: space-between;
}
.investment-top>h1{
   font-size: 25px;
   font-weight: bold;
}
.investment-top>p{
   font-size: 20px;
   font-weight: 100;
}

.profits-container{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
}
.profits-container>p{
    color:green;
    font-size: 20px;
}

.not-started{ 
    display: flex;
    gap: 10px;
   align-items: center;
    padding: 5px;
    background-color: var(--headerColor1);
    border-radius: 12px;
    margin: 0 auto;

    color:white;
}
