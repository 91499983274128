/* Navbar.css */

.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--blue-50);
    color: black;
    padding: 20px;
   
    border-bottom: 1px solid rgb(135,144,147);
}

.nav-container {
    width: 90%;
    display: flex;
    align-items: center;
    ;
    justify-content: space-between;
}
.nav-container>div {
  display: flex;
    align-items: center;
    gap:30px;
}

.logo {
    
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    background-color: var(--blue-950);
    padding: 10px;
    border-radius: 8px;
    width:100px;
    color:white;
}


.nav-container>ul {
    list-style-type: none;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    
    text-decoration: none;

}

.nav-links {
    display: flex;
    gap: 1rem;
    align-items: center;
}


.nav-links>li>a {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
    color: var(--blue-950);
}

.nav-button {
    background-color:var(--headerColor2);
    color:white;
   padding: 12px;
   width: 100px;
   border-radius: 10px;
   text-align: center;
}

.login-button{
border: 1px solid var(--headerColor2);
background-color: white;
color: var(--headerColor2);

}
.login-button:hover{
    background-color: var(--headerColor1);
    color:white;


}
.register-button{
    background-color:var(--headerColor2);
    color:white;


}
.register-button:hover{
    
    border: 1px solid var(--headerColor2);
    background-color: white;
    color:var(--headerColor2);
}



.menu-icon {
    display: none;
    font-size: 30px;
    cursor: pointer;
    color:#F41112;
}
.nav-button-wrapper{
    display: flex;
    gap: 10px;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .navbar {
       padding:10px;
    }

    .nav-container{
        gap: 6px;
    }

    .logo {
    
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        background-color: #F41112;
        padding: 8px;
        border-radius: 8px;
        width:80px;
        
    }
  
    .nav-links {
        display: none;
    }


    .nav-links-mobile {
        display: flex;
        flex-direction: column;
        position: absolute;
        gap:1.3rem;
        top: 122px;
        left: 0;
        width: 100%;
        z-index: 1;
        padding: 30px;
        background-color: white;
        border-bottom: 10px solid var(--blue-600);
        color: var(--blue-950)
        ;
    }

    .nav-button {
    
       padding: 8px;
       width: 70px;
       border-radius: 10px;
       
    }

    .menu-icon {
        display: block;
    }
}