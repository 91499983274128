.DashboardWithdrawals{

    display: flex;
    flex-direction: column;
    gap: 20px;
    
}

.over-view-container{
    background-color: white;
    padding: 18px;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.account-overview{
    
    display: flex;
    justify-content: space-between;
    gap: 18px;
    padding: 18px;
}
.account-overview>div{
   flex: 1;
   background-color: var(--blue-100);
   padding: 20px;
   border-radius: 20px;
}

.balance{
    font-size: 30px;
    font-weight: 200;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.payment-content{
    margin-top: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

@media screen and (max-width: 768px) {
    .over-view-container{   
        padding: 10px;
    }

    .account-overview{
padding: 5px;
    }
    .account-overview>div{
        flex: 1;
        background-color: antiquewhite;
        padding: 5px;
        border-radius: 20px;
        text-align: center;
     }
}