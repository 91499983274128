 @import "./keyframes.css";
 .hero-wrapper{
    background-color: black;   
        
}
.hero-container{
  
   padding:100px 0;
   width: 80%;
   margin:0 auto;
   display: flex;
}

.hero-left{
    width: 50%;
}
.hero-right{
width: 50%;;
}
.hero-right>img{
width: 100%;
}

.button-div{
    background-color: var(--headerColor1);
    border-radius: 5px;
    padding:15px;
    color:white;
}
.hero-button-wrapper{
    display: flex;
    gap:10px;
}


.sider-content{
    display: flex;
    flex-direction: column;
    gap:15px;
    font-size: 20px;
    padding: 10px;
}

.bounce{
    opacity: 1;
    transform: translateX(0);
    animation: bounce 6s infinite;
}



@media screen and (max-width: 768px) {
    .hero-container{
  flex-direction: column;
  gap:30px;
     }
    .hero-container>div{
        width:100%; 
     }

     .button-div{
        margin:0 auto;
    }
    .hero-container{
  
        padding:30px 0;
        width: 80%;
        margin:0 auto;
        display: flex;
     }
 





}