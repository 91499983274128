.DashboardHomeCard{
       
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        padding: 20px 30px;
}
.DashboardHomeCard>.icon-holder{
        
        font-size: 40px;
}
.DashboardHomeCard>h1{
       
        font-size: 18px;
        font-weight: bold;
        
}
.DashboardHomeCard>p{
       
        font-size: 38px;
        font-weight: 200;
        text-wrap: wrap;
        max-width: 100%;
        white-space:no-wrap;
        overflow: hidden;
       overflow-x: auto;
}

.dashboard-details{
display: flex;
flex-direction: column;

gap: 30px;
}

@media screen and (max-width: 768px) {
    .DashboardHomeCard>h1{
       
        text-align: center;
        
}

    .DashboardHomeCard>p{
       
        font-size: 20px;
        
}
}