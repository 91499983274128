@tailwind base;
@tailwind components;
@tailwind utilities;

 

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Rowdies:wght@300;400;700&display=swap');


*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Outfit';
    
}


:root {
    --blue-50: #eff6ff;
    --blue-100: #dbeafe;
    --blue-200: #bfdbfe;
    --blue-300: #93c5fd;
    --blue-400: #60a5fa;
    --blue-500: #3b82f6;
    --blue-600: #2563eb;
    --blue-700: #1d4ed8;
    --blue-800: #1e40af;
    --blue-900: #1e3a8a;
    --blue-950: #172554;

    
 --textColor1: var(--blue-600); ; 
    --headerColor1: var(--blue-400);
    --headerColor2:var(--blue-900);
    --headerColor3:var(--blue-800);
    --backgroundLight: var(--blue-50);
    --backgroundLight2:var(--blue-100);

 /* --textColor1: #707070; 
    --headerColor1:rgb(182,135,86);
    --headerColor2:rgb(98, 73, 45);
    --headerColor3:rgb(231, 198, 161);
    --backgroundLight:#F8F8F8;
    --backgroundLight2:#F1F1F1; */

}
.PageHeader{
    font-family: 'Rowdies';
        font-size: 45px;
        padding: 10px 0;
        color: var(--headerColor1);
}

.sectionheader{
    font-size: 30px;
    font-family: 'Rowdies';
    color: var(--headerColor1);
    text-align: center;
    padding-bottom: 15px;
  
}
.sectiondescription{
    font-size: 20px;
  
    color: var(--blue-800);
    text-align: center;
    padding-bottom: 15px;
}

.Theader{
    
        font-family: 'Rowdies';
        font-size: 25px;
        color: var(--headerColor1);
} 
.TDes{
    font-size: 20px;
    color: var(--blue-200);
}  


a{
    color:rgb(135,144,147);
    text-decoration: none;
    
}
a:hover{
    color: #B68756;
   
}
form{
    display: flex;
    flex-direction: column;
    gap:10px;
    width: 100%;
}

form>button{
    padding:10px;
    background-color:var(--headerColor1);
    text-align: center;
    color:white;
    border-radius: 20px;
}
form>button:hover{
    background-color: var(--headerColor2); 
}
input, textarea, select{
    outline:none;
    border: 1px solid var(--headerColor1);
    color:var(--headerColor1);
    font-size: 18px;

    padding: 10px;
    border-radius: 12px;
    width: 100%;
}

button{
    padding:10px;
    background-color:var(--headerColor1);
    text-align: center;
    color:white;
    border-radius: 20px;
}


.form-error-container {
    width: 80%;
    margin: auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
   background-color: var(--headerColor1);
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
  
  }
  
  .form-error-container>p {
    margin: auto;
    text-align: center;
    color:white;
    font-size: 18px;
  }
  .form-error-container>p>i {
    
    font-size: 25px;
  }
 
  
  .form-sucess-container{
    width: 80%;
    margin: auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid darkgreen;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .form-sucess-container>p{
    margin: auto;
    text-align: center;
    color: green;
  }


@media screen and (max-width: 768px) {
    .PageHeader{
        font-family: 'Rowdies';
            font-size: 30px;
            padding: 10px 0;
            color: var(--headerColor1);
    }
    .Theader{
        font-family: 'Rowdies';
        font-size: 20px;
        text-align: center;
} 
.TDes{
    font-size: 18px;
} 
}