/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    width: auto;
    max-width: 80%;
}

.close-button {
    position: absolute;
     top: 0px; 
     right: 10px; 
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: red;
}

.modal-content img {
    max-width: 100%;
    height: auto;
    display: block;
}
