.Dashboard{
    display: flex;
    justify-content: space-between;
   
} 

.DashboardMenu{
    background-color: var(--blue-50);
    color:var(--headerColor2);
   
   border-right: 14px solid var(--blue-100);

}
.DashboardMenu>ul{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin:35px 0;
    justify-content: center;
}
.DashboardMenu>ul>li{
   padding: 20px;
   display: flex;
   gap:15px;
   align-items: center;

}
.DashboardMenu>ul>li>i{
   font-size: 18px;
   width:10%;
  
}
.DashboardMenu>ul>li>p{
   font-size: 18px;
   text-align: left;
}

.selected {
  color:var(--blue-500);
  background-color: var(--blue-100);
}
.DashboardMenu-Tab{

background-color:var(--blue-50);;
flex: 1;
padding: 16px;
min-height: 800px;

}

.menu-toggle{
   display: flex;
   gap:20px;
   align-items: center;
   padding: 25px;
   background-color: rgba(var(--headerColor3), 0.8);
   margin-bottom: 16px;
   font-weight: bold;
   font-size: 20px;
   border-radius: 50px;
   color:var(--headerColor2)
}
.logout-button{
   margin-top: 60px;
   display: flex;
   gap :10px;
   align-items: center;
}


@media screen and (max-width: 768px) {
   .Dashboard{
    flex-direction: column;
    position: relative;
   }
   .DashboardMenu{
    display: none;
   }
   .MobileMenu{
    display: block;
    position: absolute;
    top: 100px;
    z-index: 999;
    background-color: var(--headerColor3); 
    color:white;
    padding:15px;
    width: 100%;
    font-size: 20px;
  
     
    
   }
   .MobileMenu>ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:10px;
   }
   .MobileMenu>ul>li{
      padding: 20px;
      display: flex;
      gap:15px;
      align-items: center;
   }
  }
  