
.dashboard-home{
    display: flex;
    flex-direction: column;
    gap:16px;
    color:var(--headerColor2)
}
.dashboard-intro{
    border-radius: 20px;
   
}
.dashboard-intro>p{
    font-size: 20px;
    padding: 40px 16px;
   
}

.cardContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.carddiv{
   
   width: calc(100%/4.5);

   background-color: var(--blue-100);
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   border-radius: 20px;
   
}

.investment-header{
    font-size: 25px;

}

.Markets-wrapper{
    padding:30px 18px;
    background-color: white;
    border-radius: 10px;
}

.market-container{
    padding:18px;
    display: flex;
    flex-wrap: wrap;
 justify-content: space-evenly;
 gap: 20px;

}
.market-container>div{
   flex: auto;
   /* border: 1px solid black; */
   display: flex;
   justify-content: left;
   align-items: center;
 
}

/* .market-container> div:hover{
   width: 100%;
   height: 60%;
} */

@media screen and (max-width: 768px) {

    .cardContainer{
       justify-content: space-between;
      
    }

    .carddiv{
   
        width: calc(100%/2.1); 
    
       margin-bottom: 16px;
        
     }
     .market-container{
       flex-direction: column;
    
    }
    .market-container>div{
        flex: auto;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        align-items: center;
     
     }
}